import React, { Fragment } from "react"
import ProductActions from "./productActions"
import SingleProductSetting from "./singleProductSetting"

function MultiProductsSetting(props) {
  const {
    token,
    shop,
    host,
    state,
    setState,
    productPickerShow,
    products,
    index: offerIndex,
    error,
    setError,
    currency,
    currencySymbol = "$",
    productEdit,
    setProductEdit,
  } = props

  return (
    <Fragment>
      {products.map((product, productIndex) => {
        return productEdit[0] === offerIndex &&
          productEdit[1] === productIndex ? (
          <SingleProductSetting
            key={productIndex}
            token={token}
            shop={shop}
            host={host}
            state={state}
            setState={setState}
            productPickerShow={productPickerShow}
            products={products}
            offerIndex={offerIndex}
            currency={currency}
            currencySymbol={currencySymbol}
            productEdit={productEdit}
            setProductEdit={setProductEdit}
            productIndex={productIndex}
            product={product}
            error={error}
            setError={setError}
          />
        ) : (
          <ProductActions
            product={product}
            products={products}
            offerIndex={offerIndex}
            productIndex={productIndex}
            state={state}
            setState={setState}
            productEdit={productEdit}
            setProductEdit={setProductEdit}
            key={productIndex}
          />
        )
      })}
    </Fragment>
  )
}
export default MultiProductsSetting
